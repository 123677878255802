//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.icon-color {
  fill: red !important;
}

.short-name {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100%;
  color: $primary;
  background-color: $warning;
}

.text-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $text;
}

.title {
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  color: $text;

  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 600px) {
  .content-svg-auth {
    display: none;
  }
}
.content-svg-auth-botoom {
}
.is-valid {
  background-image: none !important;
}
.is-invalid {
  background-image: none !important;
}

.button-output {
  background-color: $white;
  color: $primary;
  padding: 10px 20px;
  border: 1px solid $primary;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.button-output:hover {
  background-color: #fff;
}

.bg-head-table {
  background: #dcdcdc;
}

.state-inactive {
  color: $danger;
  border-radius: 16px;
  border: 1px solid $danger;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.state-active {
  color: $primary;
  border-radius: 16px;
  border: 1px solid $primary;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.state-pending {
  color: $warning;
  border-radius: 16px;
  border: 1px solid $warning;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.state-badge {
  border-radius: 16px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;

}

.title-form {
  font-size: 20px;
  line-height: 28px;
  color: $text;
}
.subtitle-form {
  font-size: 16px;
  line-height: 28px;
  color: $text;
  font-weight: 600;
}
.subtitle-form-total {
  font-size: 16px;
  line-height: 20px;
  color: $text;
}
.card-total {
  width: 80%;
  background-color: white;
  border-radius: 16px;
  border: 1px solid rgba(220, 220, 220, 1);
  @media (max-width: 970px) {
    width: 100%;
  }
}
// @media (max-width:970px) {
//   .card-total{
//     width: 100%;
//   }
// }
.hr {
  border: none;
  border-top: 1px solid $gray-300;
  width: 100%;
}

.rdrDateDisplayWrapper {
  display: none;
}

.css-13cymwt-control:focus {
  border-color: $primary !important;
  border: 2px solid $primary !important;
}

.custom-select .Select__control:focus {
  border-color: #ff0000 !important;
  box-shadow: 0 0 5px #ff0000 !important;
}

.form-control.text-transparent {
  color: transparent !important;
}

/* React-date-range */
span.rdrStartEdge {
  background: $primary !important;
}

span.rdrInRange {
  background: $primary !important;
}

span.rdrEndEdge {
  background: $primary !important;
}

span.rdrDayNumber span:after {
  background: $primary !important;
}

/* toast */

.custom-toast-success {
  background: #b2e3cf;
}

.custom-toast-error {
  background: #f9b4b4;
}

.custom-toast {
  background: rgba(247, 155, 64, 0.44);
}

.Toastify__toast-container {
  width: auto !important;
}

button.Toastify__close-button.Toastify__close-button--light {
  display: none !important;
}

.Toastify__toast {
  padding: 0 !important;
  min-height: 50px !important;
}

.Toastify__toast-body {
  margin: 0 !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.Toastify__toast-body > div:last-child {
  color: #525252;
}

/* card wishlist */

.container-whislist {
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  margin-bottom: 20px;
}

.supplier-title {
  @media (max-width: 730px) {
    width: 100%;
    min-width: none;
  }
}
.card-wishlist {
  width: 100%;
  min-height: 126px;
  height: 100%;
  border: 2px solid #72bf44;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-gray-2 {
  font-size: 14px;
  font-weight: 500;
  color: #989898;
}

.entity-supplier {
  min-width: 200px;
  z-index: 3;
  @media (max-width: 576px) {
    width: 100%;
    min-width: none;
  }
}

.h-44 {
  height: 44px;
}

.card-wishlist-title {
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #989898;
  min-width: 55px;
}

.card-wishlist-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #464646;
}

.card-wishlist-sku {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  color: #989898;
}

/* counter */

.counter-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.decrement-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 0 7px;
  border-radius: 0;
  transition: background-color 0.3s ease;
}

.btn-icon-manage-quanity {
  width: 40px;
  height: 40px;
}

.decrement-button.wdb {
  width: 31px;
}

.increment-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding: 0 3px;
  border-radius: 0;
  transition: background-color 0.3s ease;
}

.decrement-button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: $primary;
}

.increment-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: $primary;
}

.count {
  display: flex;
  justify-content: center;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-right: none;
  border-left: none;
  width: 60px;
  font-weight: 400;
  font-size: 14px;
  padding: 3.5px;
  text-align: center; /* Centra el texto horizontalmente */
  line-height: 1.5; /* Ajusta el valor según el tamaño del input */
  display: block;
  margin: 0 auto;
}

.input-count {
  height: 40px;
}

/* category wishlist */
.bg-color-1 {
  background-color: #72bf44;
}

.bg-color-2 {
  background-color: #f58220;
}

.bg-color-3 {
  background-color: #34affc;
}

.bg-color-4 {
  background-color: #8542db;
}

.bg-color-5 {
  background-color: #fcaf17;
}

/* Style Prompt*/

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}

.modal-card {
  width: 384px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* configuration */
.type-file {
  color: #00A160;
  background-color: #EDFFF7;
  height: 30px;
  min-width: 30px;
  max-width: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hidden control input number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*  */

.offcanvas.offcanvas-end.show {
  width: 500px;
}

// style radio button
input[type='radio'] {
  &.form-radio-input {
    width: 20px;
    height: 20px;
    accent-color: #00A160;
  }
}

.btn-non-style {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.file-event-img {
  min-height: 61px;
  max-height: 70px;
  min-width: 68px;
  max-width: 75px;
  object-fit: contain;
}