// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~flatpickr/dist/flatpickr.css';

#root {
  display: contents;
}

.form-control::placeholder {
  color: #BDBDBD !important;
  opacity: 1; /* Firefox */
  font-weight: 300;
}
.form-control {
  color: #3D3D3D !important;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

.form-control-auth {
  padding-left: 42px !important;
}

input:focus { caret-color: #00A160;}

.bs-modal-width {
  width: 400px;
  border-radius: 14px;
}

.bg-qr{
  background:url("../../../../public/media/imgs/bgqr.png") no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  padding: 47px 25px;
  position: relative;
}

.arrow-left-container{
  display: flex;
  gap:10px;
  align-items: center;
  position: absolute;
  z-index: 3;
}

span.text-qr{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  width: fit-content;
}
span.link-green{
  color:#00A160;
  cursor: pointer;
  text-decoration: underline !important;
}

span.label-customer{
    font-size: 18px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0em;
    color:#989898;
}

.validation-qr{
   position: absolute;
   display: flex;
   gap:18px;
   flex-direction: column;
   align-items: center;
   padding-top: 30px;
   span.text-white{
    font-size: 30px;
    margin-top: 10px;
   }
}
.validation-qr.no-gap{
   gap:0px;
}
.validation-qr .spinner-border{
  width: 111px;
  height: 111px;
  border-width: 8px;
}

.swiper-slide{
  width: auto !important;
}

.toast-message{
    max-width: 480px;
    min-height: 48px;
    padding: 12px 16px;
    border-radius: 8px;
    color:#525252;
    display: flex;
    align-items: center;
    gap:16px;
    span{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;  
    }
}
.toast-message.bgt-success{
    background: #B2E3CF;
}
.toast-message.bgt-warning{
  background: rgba(247, 155, 64, 0.44)
}



.btn-disabled{
  background: #EFEFEF !important;
  color:#BDBDBD !important;
}

.bs-modal-width-md{
  max-width: 590px;
  border-radius: 14px;
}

.modal-content  {
  -webkit-border-radius: 14px !important;
  -moz-border-radius: 14px !important;
  border-radius: 14px !important; 
}

.button-icon {
  border-radius: 100% !important;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 6px;
  padding-left: 6px;
}
.button-icon:hover {
  transition: ease-in-out all 3s;
  background-color: #f9f9f9 !important;
}

.button-icon:active {
  scale: 0.95;
  background-color: #e9e9e9 !important;
}

.form-select-solid {
  border: 1px solid #DCDCDC !important;
  background: #fff !important;
  font-weight: 300;
}
.form-select-solid-disabled {
  border: 1px solid #DCDCDC !important;
  background: #efefef !important;
  font-weight: 300;
}
.form-control-solid {
  border: 1px solid #DCDCDC !important;
  background: #efefef !important;
  font-weight: 300;
}
.form-control-solid::placeholder {
  color: #656565 !important;
}

.card-event-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #464646;
}

.table-wishlist{
  height: auto;
  max-height: calc(65vh - 50px);
  @media (max-width: 1199px) {
    max-height: calc(73vh - 50px);
  }
}

.table-list{
  height: auto;
  max-height: calc(60vh - 50px);


  @media (min-height: 500px){
    max-height: calc(60vh - 50px);
  }

  @media (min-height: 870px){
    max-height: calc(70vh - 50px);
  }

  @media (min-height: 1300px){
    max-height: calc(75vh - 50px);
  }

  @media (min-height: 1500px){
    max-height: calc(85vh - 50px);
  }
}