//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Gray colors
$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;
$gray-600-dark: #6d6d80;
$gray-700-dark: #92929f;
$gray-800-dark: #cdcdde;
$gray-900-dark: #ffffff;

// Theme colors
// Primary
$primary: #00A160;
$primary-active: #00A160;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #EF4444;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #F59E0B;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

// Card Box Shadow
$card-border-enabled: true;
$card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
$card-box-shadow-dark: none;

// Body
$body-bg-dark: #1c1c1c;

// Text
$text:#464646!important;


.tw-neutral-50 {
    color :#fafafa !important;
}
.tw-neutral-100 {
    color :#f5f5f5 !important;
}
.tw-neutral-200 {
    color :#e5e5e5 !important;
}
.tw-neutral-300 {
    color :#d4d4d4 !important;
}
.tw-neutral-400 {
    color : #a3a3a3 !important;
}
.tw-neutral-500 {
    color: #737373 !important;
}
.tw-neutral-600 {
    color: #525252 !important;
}
.tw-neutral-700 {
    color: #404040 !important;
}
.tw-neutral-800 {
    color: #464646 !important;
}
.tw-neutral-900 {
    color: #171717 !important;
}

.tw-sky-blue-500 {
    color:#0CB1EB !important;
}

.tw-orange-400 {
    color:#F79B40 !important;
}

.tw-purple-600 {
    color:#8542DB !important;
}

.tw-fucsia-600 {
    color:#E919A2 !important;
}

.tw-destructive-500 {
    color:#EF4444 !important;
}

.tw-green-700 {
    color:#00A160 !important;
}

.bg-transparent{
    background-color: transparent !important;
}

.bg-green-400{
    background-color: #72BF44 !important;
}

.bg-skyblue-500{
    background-color: #0CB1EB !important;
}




